<template>
  <v-card>
    <v-card-title>
      <GXGeneDialog
        :visible="dialog"
        :item="editedItem"
        @save="save"
        @close="close"
      />
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >¿Seguro que quieres eliminar este gen
            {{ editedItem.symbol }}?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Eliminar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col cols="3">
        <v-btn
          @click="dialog = true"
          color="blue-grey"
          class="ma-2 white--text"
        >
          Nuevo
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>

      <v-col cols="4">
        <v-select
          :items="searchableFields"
          v-model="activeFilter"
          item-text="text"
          item-value="value"
          label="Campos"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="filterSearch"
          append-icon="mdi-magnify"
          label="Filtrar"
        ></v-text-field>
      </v-col>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="selectedFields"
          :items="allFields"
          label="Columnas"
          multiple
          chips
        ></v-combobox>
      </v-col>
    </v-row>
    <v-data-table
      :headers="selectedHeaders"
      :items="gxGenes"
      :options.sync="options"
      multi-sort
      :server-items-length="totalGxGenes"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import GXGeneDialog from "@/components/mylogy/dialogs/GXGeneDialog.vue";

export default {
  components: { GXGeneDialog },
  data() {
    return {
      totalSamples: 0,
      gxGenes: [],
      filterSearch: null,
      loading: true,
      activeFilter: "symbol",
      options: {},
      editedIndex: -1,
      dialog: false,
      dialogDelete: false,
      defaultItem: {
        id: "",
        symbol: "",
        name_es: "",
        name_po: "",
        summary_es: "",
        summary_en: "",
        summary_po: "",
        omim: "",
        locus: "",
        category: "",
        weight: 0,
        gxGeneId: "",
        id_panel: "",
        data_matrix_key: "",
        haplotype_builder_string: "",
        texts: {
          gray: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          },
          green: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          },
          yellow: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          },
          orange: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          },
          red: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          }
        }
      },
      editedItem: {
        id: "",
        symbol: "",
        name_es: "",
        name_po: "",
        summary_es: "",
        summary_en: "",
        summary_po: "",
        omim: "",
        gxGeneId: "",
        locus: "",
        category: "",
        weight: "",
        id_panel: "",
        data_matrix_key: "",
        haplotype_builder_string: "",
        texts: {
          gray: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          },
          green: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          },
          yellow: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          },
          orange: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          },
          red: {
            en: {
              phenotype: "",
              activity: ""
            },
            es: {
              phenotype: "",
              activity: ""
            },
            po: {
              phenotype: "",
              activity: ""
            }
          }
        }
      },
      selectedFields: [
        "Símbolo",
        "OMIM",
        "Categoría",
        "Nombre ES",
        "Peso",
        "Acciones"
      ],
      headers: [
        {
          text: "Símbolo",
          align: "start",
          sortable: true,
          searchable: true,
          value: "symbol"
        },
        {
          text: "OMIM",
          sortable: true,
          searchable: true,
          value: "omim"
        },
        {
          text: "Locus",
          sortable: true,
          searchable: true,
          value: "locus"
        },
        {
          text: "Categoría",
          sortable: true,
          searchable: true,
          value: "category"
        },
        {
          text: "Peso",
          sortable: true,
          searchable: true,
          value: "weight"
        },
        {
          text: "Nombre ES",
          sortable: true,
          searchable: true,
          value: "name_es"
        },
        {
          text: "Nombre EN",
          sortable: true,
          searchable: true,
          value: "name_en"
        },
        {
          text: "Nombre PO",
          sortable: true,
          searchable: true,
          value: "name_po"
        },
        {
          text: "ID Panel",
          sortable: true,
          searchable: true,
          value: "id_panel"
        },
        { text: "Acciones", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    searchableFields() {
      return this.selectedHeaders.filter(h => h.searchable);
    },
    allFields() {
      return this.headers.map(h => h.text);
    },
    selectedHeaders() {
      return this.headers.filter(h => {
        return this.selectedFields.includes(h.text);
      });
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    filterSearch: {
      handler() {
        this.getDataFromApi();
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.gxGenes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.gxGenes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let self = this;
      Strapi.delete(`/pgx-genes/${this.editedItem.gxGeneId}`)
        .then(() => {
          self.$alertify.success("Gen eliminado");
        })
        .catch(() => {
          self.$alertify.error("Ha ocurrido un error");
        })
        .then(() => {
          self.closeDelete();
          self.getDataFromApi();
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      let self = this;
      if (self.editedItem.gxGeneId != "") {
        Strapi.put(`/pgx-genes/${self.editedItem.gxGeneId}`, self.editedItem)
          .then(() => {
            self.$alertify.success("Gen actualizado");
          })
          .catch(() => {
            self.$alertify.error("Ha ocurrido un error");
          })
          .then(() => {
            self.close();
            self.getDataFromApi();
          });
      } else {
        Strapi.post(`/pgx-genes`, self.editedItem)
          .then(() => {
            self.$alertify.success("Gen añadido");
          })
          .catch(() => {
            self.$alertify.error("Ha ocurrido un error");
          })
          .then(() => {
            self.close();
            self.getDataFromApi();
          });
      }
    },
    getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const _sort = sortBy
        .map(function(e, i) {
          return `${e}:${(sortDesc[i] && "DESC") || "ASC"}`;
        })
        .join(",");
      const qs = require("qs");

      let params = {
        _start: (page - 1) * itemsPerPage,
        _limit: itemsPerPage
      };

      if (sortBy.length > 0) {
        params._sort = _sort;
      }

      if (this.filterSearch) {
        params._where = {
          _or: [
            { FIELD: this.filterSearch },
            { FIELD_contains: this.filterSearch }
          ]
        };
      }

      const query = qs
        .stringify(params, {
          encodeValuesOnly: true
        })
        .replace(/FIELD/g, this.activeFilter);

      Strapi.get(`/pgx-genes?${query}`).then(res => {
        this.gxGenes = res.data;
        Strapi.get(`/pgx-genes/count?${query}`).then(resCount => {
          this.totalGxGenes = resCount.data;
          this.loading = false;
        });
      });
    }
  }
};
</script>
<style lang="scss"></style>
